import { default as _91id_93l6BVh49i2mMeta } from "/home/dashboard/app:client/pages/[id].vue?macro=true";
import { default as forgot_45passwordECRiGDOas3Meta } from "/home/dashboard/app:client/pages/forgot-password.vue?macro=true";
import { default as indexQgTOdgL3QMMeta } from "/home/dashboard/app:client/pages/index.vue?macro=true";
import { default as login_45passwordA1DnI3Z0nXMeta } from "/home/dashboard/app:client/pages/login-password.vue?macro=true";
import { default as loginCr8Nd2WSp8Meta } from "/home/dashboard/app:client/pages/login.vue?macro=true";
import { default as logoutOdzZDIn3wSMeta } from "/home/dashboard/app:client/pages/logout.vue?macro=true";
import { default as registerA91WXhwh71Meta } from "/home/dashboard/app:client/pages/register.vue?macro=true";
import { default as wsjne8su7efLmMeta } from "/home/dashboard/app:client/pages/wsj.vue?macro=true";
import { default as indexZWfjvOwAiVMeta } from "/home/modules/app_barcode/views/pages/index.vue?macro=true";
import { default as indexvgOi3Epn5QMeta } from "/home/modules/app_barcode/views/pages/batch/index.vue?macro=true";
import { default as indexdidN3SguE0Meta } from "/home/modules/app_barcode/views/pages/cek-keaslian/index.vue?macro=true";
import { default as indexqgPtXG87YAMeta } from "/home/modules/app_barcode/views/pages/logs/index.vue?macro=true";
import { default as indexNa5aRXg9e0Meta } from "/home/modules/app_barcode/views/pages/manage/index.vue?macro=true";
import { default as indexcVWRfsF7jJMeta } from "/home/modules/app_barcode/views/pages/products/index.vue?macro=true";
import { default as indexjtoPGw1UYKMeta } from "/home/modules/app_barcode/views/pages/purchase/index.vue?macro=true";
import { default as indexz4KT3OW5VqMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/index.vue?macro=true";
import { default as _91sku_93nXtf6oo9woMeta } from "/home/modules/app_barcode/views/pages/products/[sku].vue?macro=true";
import { default as _91id_93mGRHUQ06LYMeta } from "/home/modules/app_barcode/views/pages/purchase/[id].vue?macro=true";
import { default as barcodesCwIt0L6DpoMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/barcodes.vue?macro=true";
import { default as parentsnH1I0XcNttMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/parents.vue?macro=true";
import { default as sandbox_45pairingYGCKSwllhoMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/sandbox-pairing.vue?macro=true";
import { default as indexpFYLsLHoWUMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/downloads/index.vue?macro=true";
import { default as generateiaVpVC8UdkMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/downloads/generate.vue?macro=true";
import { default as pdf_45barcodeD7Mi3Y9IHJMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/downloads/pdf-barcode.vue?macro=true";
import { default as pdf_45parentYse1EI8jANMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/downloads/pdf-parent.vue?macro=true";
import { default as _91parent_93jQ2p0k0lKUMeta } from "/home/modules/app_barcode/views/pages/batch/[id]/sandbox-print/[parent].vue?macro=true";
import { default as indexnfBH8SEeCCMeta } from "/home/modules/app_admin/views/pages/index.vue?macro=true";
import { default as indexZtDr262w4TMeta } from "/home/modules/app_admin/views/pages/modules/index.vue?macro=true";
import { default as index0203Y7CtARMeta } from "/home/modules/app_admin/views/pages/orca/index.vue?macro=true";
import { default as indexSEFdrVm3abMeta } from "/home/modules/app_admin/views/pages/privileges/index.vue?macro=true";
import { default as indexEzJIxtSyGAMeta } from "/home/modules/app_admin/views/pages/roles/index.vue?macro=true";
import { default as index5PabSqGvhIMeta } from "/home/modules/app_admin/views/pages/services/index.vue?macro=true";
import { default as indexltkRck3qhqMeta } from "/home/modules/app_admin/views/pages/system/index.vue?macro=true";
import { default as index4XpstHG1e8Meta } from "/home/modules/app_admin/views/pages/tasks/index.vue?macro=true";
import { default as indexyiE1mRdCJ8Meta } from "/home/modules/app_admin/views/pages/users/index.vue?macro=true";
import { default as indexpVj99Zu9AbMeta } from "/home/modules/app_admin/views/pages/wsj/index.vue?macro=true";
import { default as indexNxibWVSEHYMeta } from "/home/modules/app_admin/views/pages/orca/logs/index.vue?macro=true";
import { default as indexNaNKngSlmWMeta } from "/home/modules/app_admin/views/pages/orca/products/index.vue?macro=true";
import { default as indexptSuNLggTwMeta } from "/home/modules/app_admin/views/pages/orca/vendors/index.vue?macro=true";
import { default as index9mJOlimhs5Meta } from "/home/modules/app_admin/views/pages/orca/warehouses/index.vue?macro=true";
import { default as logshFb3h7mhRUMeta } from "/home/modules/app_admin/views/pages/users/logs.vue?macro=true";
import { default as indexxSTAAVDxtLMeta } from "/home/modules/app_admin/views/pages/users/detail/index.vue?macro=true";
import { default as logs8Jmd9FHjX4Meta } from "/home/modules/app_admin/views/pages/orca/products/logs.vue?macro=true";
import { default as logshSK791YxLCMeta } from "/home/modules/app_admin/views/pages/orca/vendors/logs.vue?macro=true";
import { default as logs4Tf5jAJT4wMeta } from "/home/modules/app_admin/views/pages/orca/warehouses/logs.vue?macro=true";
import { default as _91id_93CwzCDzN3RkMeta } from "/home/modules/app_admin/views/pages/users/detail/[id].vue?macro=true";
import { default as indexXt3SQk1LItMeta } from "/home/modules/app_coreapi/views/pages/index.vue?macro=true";
import { default as index6r8BNzpOQYMeta } from "/home/modules/app_coreapi/views/pages/logs/index.vue?macro=true";
import { default as _91id_936Eed6Z65jhMeta } from "/home/modules/app_coreapi/views/pages/logs/[id].vue?macro=true";
import { default as indexCUN1DvWRezMeta } from "/home/modules/app_orca/views/pages/index.vue?macro=true";
import { default as scan_45history6gGap6LY7UMeta } from "/home/modules/app_orca/views/pages/scan-history.vue?macro=true";
import { default as indexMX5TodemdNMeta } from "/home/modules/app_orca/views/pages/done/index.vue?macro=true";
import { default as indexNtuXedk79gMeta } from "/home/modules/app_orca/views/pages/done-process/index.vue?macro=true";
import { default as indexMzv8qk6dzhMeta } from "/home/modules/app_orca/views/pages/new-process/index.vue?macro=true";
import { default as indexeyE0kN0EfKMeta } from "/home/modules/app_orca/views/pages/on-delivery/index.vue?macro=true";
import { default as indexcI9AykSIVLMeta } from "/home/modules/app_orca/views/pages/pairing-process/index.vue?macro=true";
import { default as index26LaWr5U2IMeta } from "/home/modules/app_orca/views/pages/products/index.vue?macro=true";
import { default as indexw25pcSP9ZeMeta } from "/home/modules/app_orca/views/pages/status-barcode/index.vue?macro=true";
import { default as _91sku_93VGi5EpHpcdMeta } from "/home/modules/app_orca/views/pages/products/[sku].vue?macro=true";
import { default as indexR1dv1mBDNPMeta } from "/home/modules/app_orca/views/pages/batch/[id]/index.vue?macro=true";
import { default as parentsLPMYVALSIgMeta } from "/home/modules/app_orca/views/pages/batch/[id]/parents.vue?macro=true";
import { default as _91parent_9396jjXO3CvxMeta } from "/home/modules/app_orca/views/pages/batch/[id]/parent/[parent].vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    meta: _91id_93l6BVh49i2mMeta || {},
    component: () => import("/home/dashboard/app:client/pages/[id].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordECRiGDOas3Meta || {},
    component: () => import("/home/dashboard/app:client/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQgTOdgL3QMMeta || {},
    component: () => import("/home/dashboard/app:client/pages/index.vue")
  },
  {
    name: "login-password",
    path: "/login-password",
    meta: login_45passwordA1DnI3Z0nXMeta || {},
    component: () => import("/home/dashboard/app:client/pages/login-password.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginCr8Nd2WSp8Meta || {},
    component: () => import("/home/dashboard/app:client/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutOdzZDIn3wSMeta || {},
    component: () => import("/home/dashboard/app:client/pages/logout.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerA91WXhwh71Meta || {},
    component: () => import("/home/dashboard/app:client/pages/register.vue")
  },
  {
    name: "wsj",
    path: "/wsj",
    component: () => import("/home/dashboard/app:client/pages/wsj.vue")
  },
  {
    name: "app_barcode-index",
    path: "/barcode/",
    meta: { ...(indexZWfjvOwAiVMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/index.vue")
  },
  {
    name: "app_barcode-batch",
    path: "/barcode/batch",
    meta: { ...(indexvgOi3Epn5QMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/index.vue")
  },
  {
    name: "app_barcode-cek-keaslian",
    path: "/barcode/cek-keaslian",
    meta: { ...(indexdidN3SguE0Meta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/cek-keaslian/index.vue")
  },
  {
    name: "app_barcode-logs",
    path: "/barcode/logs",
    meta: { ...(indexqgPtXG87YAMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/logs/index.vue")
  },
  {
    name: "app_barcode-manage",
    path: "/barcode/manage",
    meta: { ...(indexNa5aRXg9e0Meta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/manage/index.vue")
  },
  {
    name: "app_barcode-products",
    path: "/barcode/products",
    meta: { ...(indexcVWRfsF7jJMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/products/index.vue")
  },
  {
    name: "app_barcode-purchase",
    path: "/barcode/purchase",
    meta: { ...(indexjtoPGw1UYKMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/purchase/index.vue")
  },
  {
    name: "app_barcode-batch-id",
    path: "/barcode/batch/:id",
    meta: { ...(indexz4KT3OW5VqMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/index.vue")
  },
  {
    name: "app_barcode-products-sku",
    path: "/barcode/products/:sku",
    meta: { ...(_91sku_93nXtf6oo9woMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/products/[sku].vue")
  },
  {
    name: "app_barcode-purchase-id",
    path: "/barcode/purchase/:id",
    meta: { ...(_91id_93mGRHUQ06LYMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/purchase/[id].vue")
  },
  {
    name: "app_barcode-batch-id-barcodes",
    path: "/barcode/batch/:id/barcodes",
    meta: { ...(barcodesCwIt0L6DpoMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/barcodes.vue")
  },
  {
    name: "app_barcode-batch-id-parents",
    path: "/barcode/batch/:id/parents",
    meta: { ...(parentsnH1I0XcNttMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/parents.vue")
  },
  {
    name: "app_barcode-batch-id-sandbox-pairing",
    path: "/barcode/batch/:id/sandbox-pairing",
    meta: { ...(sandbox_45pairingYGCKSwllhoMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/sandbox-pairing.vue")
  },
  {
    name: "app_barcode-batch-id-downloads",
    path: "/barcode/batch/:id/downloads",
    meta: { ...(indexpFYLsLHoWUMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/downloads/index.vue")
  },
  {
    name: "app_barcode-batch-id-downloads-generate",
    path: "/barcode/batch/:id/downloads/generate",
    meta: { ...(generateiaVpVC8UdkMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/downloads/generate.vue")
  },
  {
    name: "app_barcode-batch-id-downloads-pdf-barcode",
    path: "/barcode/batch/:id/downloads/pdf-barcode",
    meta: { ...(pdf_45barcodeD7Mi3Y9IHJMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/downloads/pdf-barcode.vue")
  },
  {
    name: "app_barcode-batch-id-downloads-pdf-parent",
    path: "/barcode/batch/:id/downloads/pdf-parent",
    meta: { ...(pdf_45parentYse1EI8jANMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/downloads/pdf-parent.vue")
  },
  {
    name: "app_barcode-batch-id-sandbox-print-parent",
    path: "/barcode/batch/:id/sandbox-print/:parent",
    meta: { ...(_91parent_93jQ2p0k0lKUMeta || {}), ...{"app":"app_barcode"} },
    component: () => import("/home/modules/app_barcode/views/pages/batch/[id]/sandbox-print/[parent].vue")
  },
  {
    name: "app_admin-index",
    path: "/admin/",
    meta: { ...(indexnfBH8SEeCCMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/index.vue")
  },
  {
    name: "app_admin-modules",
    path: "/admin/modules",
    meta: { ...(indexZtDr262w4TMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/modules/index.vue")
  },
  {
    name: "app_admin-orca",
    path: "/admin/orca",
    meta: { ...(index0203Y7CtARMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/index.vue")
  },
  {
    name: "app_admin-privileges",
    path: "/admin/privileges",
    meta: { ...(indexSEFdrVm3abMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/privileges/index.vue")
  },
  {
    name: "app_admin-roles",
    path: "/admin/roles",
    meta: { ...(indexEzJIxtSyGAMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/roles/index.vue")
  },
  {
    name: "app_admin-services",
    path: "/admin/services",
    meta: { ...(index5PabSqGvhIMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/services/index.vue")
  },
  {
    name: "app_admin-system",
    path: "/admin/system",
    meta: { ...(indexltkRck3qhqMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/system/index.vue")
  },
  {
    name: "app_admin-tasks",
    path: "/admin/tasks",
    meta: { ...(index4XpstHG1e8Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/tasks/index.vue")
  },
  {
    name: "app_admin-users",
    path: "/admin/users",
    meta: { ...(indexyiE1mRdCJ8Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/index.vue")
  },
  {
    name: "app_admin-wsj",
    path: "/admin/wsj",
    meta: { ...(indexpVj99Zu9AbMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/wsj/index.vue")
  },
  {
    name: "app_admin-orca-logs",
    path: "/admin/orca/logs",
    meta: { ...(indexNxibWVSEHYMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/logs/index.vue")
  },
  {
    name: "app_admin-orca-products",
    path: "/admin/orca/products",
    meta: { ...(indexNaNKngSlmWMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/products/index.vue")
  },
  {
    name: "app_admin-orca-vendors",
    path: "/admin/orca/vendors",
    meta: { ...(indexptSuNLggTwMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/vendors/index.vue")
  },
  {
    name: "app_admin-orca-warehouses",
    path: "/admin/orca/warehouses",
    meta: { ...(index9mJOlimhs5Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/warehouses/index.vue")
  },
  {
    name: "app_admin-users-logs",
    path: "/admin/users/logs",
    meta: { ...(logshFb3h7mhRUMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/logs.vue")
  },
  {
    name: "app_admin-users-detail",
    path: "/admin/users/detail",
    meta: { ...(indexxSTAAVDxtLMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/detail/index.vue")
  },
  {
    name: "app_admin-orca-products-logs",
    path: "/admin/orca/products/logs",
    meta: { ...(logs8Jmd9FHjX4Meta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/products/logs.vue")
  },
  {
    name: "app_admin-orca-vendors-logs",
    path: "/admin/orca/vendors/logs",
    meta: { ...(logshSK791YxLCMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/vendors/logs.vue")
  },
  {
    name: "app_admin-orca-warehouses-logs",
    path: "/admin/orca/warehouses/logs",
    meta: { ...(logs4Tf5jAJT4wMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/orca/warehouses/logs.vue")
  },
  {
    name: "app_admin-users-detail-id",
    path: "/admin/users/detail/:id",
    meta: { ...(_91id_93CwzCDzN3RkMeta || {}), ...{"app":"app_admin"} },
    component: () => import("/home/modules/app_admin/views/pages/users/detail/[id].vue")
  },
  {
    name: "app_coreapi-index",
    path: "/admin/coreapi/",
    meta: { ...(indexXt3SQk1LItMeta || {}), ...{"app":"app_coreapi"} },
    component: () => import("/home/modules/app_coreapi/views/pages/index.vue")
  },
  {
    name: "app_coreapi-logs",
    path: "/admin/coreapi/logs",
    meta: { ...(index6r8BNzpOQYMeta || {}), ...{"app":"app_coreapi"} },
    component: () => import("/home/modules/app_coreapi/views/pages/logs/index.vue")
  },
  {
    name: "app_coreapi-logs-id",
    path: "/admin/coreapi/logs/:id",
    meta: { ...(_91id_936Eed6Z65jhMeta || {}), ...{"app":"app_coreapi"} },
    component: () => import("/home/modules/app_coreapi/views/pages/logs/[id].vue")
  },
  {
    name: "app_orca-index",
    path: "/orca/",
    meta: { ...(indexCUN1DvWRezMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/index.vue")
  },
  {
    name: "app_orca-scan-history",
    path: "/orca/scan-history",
    meta: { ...(scan_45history6gGap6LY7UMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/scan-history.vue")
  },
  {
    name: "app_orca-done",
    path: "/orca/done",
    meta: { ...(indexMX5TodemdNMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/done/index.vue")
  },
  {
    name: "app_orca-done-process",
    path: "/orca/done-process",
    meta: { ...(indexNtuXedk79gMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/done-process/index.vue")
  },
  {
    name: "app_orca-new-process",
    path: "/orca/new-process",
    meta: { ...(indexMzv8qk6dzhMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/new-process/index.vue")
  },
  {
    name: "app_orca-on-delivery",
    path: "/orca/on-delivery",
    meta: { ...(indexeyE0kN0EfKMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/on-delivery/index.vue")
  },
  {
    name: "app_orca-pairing-process",
    path: "/orca/pairing-process",
    meta: { ...(indexcI9AykSIVLMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/pairing-process/index.vue")
  },
  {
    name: "app_orca-products",
    path: "/orca/products",
    meta: { ...(index26LaWr5U2IMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/products/index.vue")
  },
  {
    name: "app_orca-status-barcode",
    path: "/orca/status-barcode",
    meta: { ...(indexw25pcSP9ZeMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/status-barcode/index.vue")
  },
  {
    name: "app_orca-products-sku",
    path: "/orca/products/:sku",
    meta: { ...(_91sku_93VGi5EpHpcdMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/products/[sku].vue")
  },
  {
    name: "app_orca-batch-id",
    path: "/orca/batch/:id",
    meta: { ...(indexR1dv1mBDNPMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/batch/[id]/index.vue")
  },
  {
    name: "app_orca-batch-id-parents",
    path: "/orca/batch/:id/parents",
    meta: { ...(parentsLPMYVALSIgMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/batch/[id]/parents.vue")
  },
  {
    name: "app_orca-batch-id-parent-parent",
    path: "/orca/batch/:id/parent/:parent",
    meta: { ...(_91parent_9396jjXO3CvxMeta || {}), ...{"app":"app_orca"} },
    component: () => import("/home/modules/app_orca/views/pages/batch/[id]/parent/[parent].vue")
  }
]