<template lang="pug">
NuxtLayout
    Html(translate='no')
    NuxtLoadingIndicator
    NuxtPage
    UNotifications             
</template>
<script setup lang="ts">
//provideHeadlessUseId(() => useId());
useHead({
    script: [
        { src: '/newrelic.js', defer: true }
    ]
});

</script>
