<template lang="pug">
ClientOnly
    USlideover(
        v-model="store.showValue[id]" 
    )
        div(v-if="title !== ''")
            .flex.flex-1.gap-2.p-3
                .flex.self-center(v-if="icon !== ''")
                    UIcon(:name="icon")
                .flex.flex-1
                    .flex.self-center.font-bold {{ title }}
                .flex
                    UButton(variant="link" @click="store.show[id] = false")
                        UIcon.w-5.h-5(name="i-carbon-close-large")
            .relative.h-svh.overflow-y-scroll(
                ref="wMainBox"
            )
                .px-3.pb-3.flex.overflow-y-scroll(
                    :style="stl"
                )
                    slot
        slot(v-else)
</template>
<script setup lang="ts">
import { bool } from "yup";
import { RDrawerStore } from "../store";
import { onMounted } from "vue";
const store = RDrawerStore();
const emit = defineEmits(["open", "close"]);
const props = defineProps({
    title: {
        type: String,
        default: "",
    },
    preventClose: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: "",
    },
    id: {
        type: String,
        default: "",
    },
    fullscreen: {
        type: Boolean,
        default: false,
    },
    xl: {
        type: Boolean,
        default: false,
    },
});
const res = reactive({
    show: computed(() => store.show),
});
const wMainBox = ref();
const stl = ref({});
const setHeight = () => {
    if (window) {
        if (wMainBox.value) {
            const t = wMainBox.value.getBoundingClientRect();
            if (t) {
                console.log(t.height, t.top);
                stl.value = {
                    maxHeight: `${window.innerHeight - t.top}px`,
                };
            }
        }
    }
};

watch(
    () => store.show[props.id],
    (newVal) => {
        if (newVal) {
            setTimeout(() => {
                setHeight();
            }, 1000);
        }
        // open close
        if (newVal) {
            emit("open", props.id);
        } else {
            emit("close", props.id);
        }
    },
);
const wdth = ref("min-w-md max-w-md");
onMounted(() => {
    if (props.xl) {
        // wdth.value = "w-lg min-w-lg max-w-lg";
    }
});
</script>
