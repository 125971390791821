import QrReader from 'vue3-qr-reader';
import { QrStream } from 'vue3-qr-reader';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('QrStream', QrStream);
    return {
        provide: {
            QrReader: QrReader
        },
    };
});
