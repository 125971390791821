import ModalComponents from './components/index.vue'
import { RModalStore } from './store'
export default defineNuxtPlugin((nuxtApp) => {
    const store = RModalStore()
    nuxtApp.vueApp.component('RModal', ModalComponents)

    // // provide
    return {
        provide: {
            RModal: {
                store: store,
                show: (id: string) => {
                    store.show[id] = true
                },
                hide: (id: string) => {
                    store.show[id] = false
                },
            },
        },
    }
})
