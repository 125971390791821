<template lang="pug">
ClientOnly
    //- Teleport(to='body' v-if="res.ready")
    UModal(:ui="uix" v-model="store.showValue[id]" :preventClose="preventClose" :fullscreen="fullscreen")
        UCard(v-if="title !== ''" :ui="{header: {padding: 'py-2 px-4'}, footer: {padding: 'py-3 px-4'}}")
            template(#header)
                .flex.flex-1.gap-2
                    .flex.self-center(v-if="icon !== ''")
                        UIcon(:name="icon")
                    .flex.flex-1
                        .flex.self-center.font-bold {{ title }}
                    .flex
                        UButton(variant="link" @click="store.show[id] = false")
                            UIcon.w-5.h-5(name="i-carbon-close-large")
            slot
            template(#footer v-if="$slots.footer")
                slot(name="footer")

        slot(v-else)
</template>
<script setup lang="ts">
import { bool } from 'yup'
import { RModalStore } from '../store'
import { onMounted } from 'vue'
const store = RModalStore()
const emit = defineEmits(['open', 'close'])
const uix = ref({})
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    preventClose: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        default: '',
    },
    sm: {
        type: Boolean,
        default: false,
    },
    md: {
        type: Boolean,
        default: false,
    },
    lg: {
        type: Boolean,
        default: false,
    },
    xl: {
        type: Boolean,
        default: false,
    },
    fullscreen: {
        type: Boolean,
        default: false,
    },
})
const res = reactive({
    show: computed(() => store.show),
})

onMounted(() => {
    if (props.sm) {
        uix.value = {
            width: 'w-sm min-w-sm max-w-sm',
        }
    } else if (props.md) {
        uix.value = {
            width: 'w-md min-w-md max-w-md',
        }
    } else if (props.lg) {
        uix.value = {
            width: 'w-lg min-w-lg max-w-lg',
        }
    } else if (props.xl) {
        uix.value = {
            width: 'w-xl min-w-xl max-w-xl',
        }
    }
})

watch(
    () => store.show[props.id],
    (newVal) => {
        // open close
        if (newVal) {
            emit('open', props.id)
        } else {
            emit('close', props.id)
        }
    },
)
</script>
