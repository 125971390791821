export default defineAppConfig({
    ui: {
        strategy: "override",
        gray: "neutral",
        primary: "indigo",

        notifications: {
            position: "top-0 right-0",
        },
        dropdown: {
            container: "z-[100] group",
        },
        badge: {
            color: {
                info: {
                    subtle: "ring-1 ring-inset ring-blue-300 dark:ring-blue-500 text-blue-600 dark:text-blue-600 bg-white dark:bg-blue-200",
                },
                orange: {
                    subtle: "ring-1 ring-inset ring-orange-300 dark:ring-orange-500 text-orange-600 dark:text-orange-600 bg-white dark:bg-orange-200",
                },
                green: {
                    subtle: "ring-1 ring-inset ring-green-300 dark:ring-green-500 text-green-600 dark:text-green-600 bg-green-50 dark:bg-green-200",
                },
                teal: {
                    subtle: "ring-1 ring-inset ring-teal-300 dark:ring-teal-500 text-teal-600 dark:text-teal-600 bg-teal-50 dark:bg-teal-200",
                },
                rose: {
                    subtle: "ring-1 ring-inset ring-rose-300 dark:ring-rose-500 text-rose-600 dark:text-rose-600 bg-rose-50 dark:bg-rose-200",
                },
                purple: {
                    subtle: "ring-1 ring-inset ring-purple-300 dark:ring-purple-500 text-purple-600 dark:text-purple-600 bg-purple-50 dark:bg-purple-200",
                },
            },
            variant: {
                subtle: "bg-{color}-50 dark:bg-{color}-400 dark:bg-opacity-10 text-{color}-500 dark:text-{color}-400 ring-1 ring-inset ring-{color}-500 dark:ring-{color}-400 ring-opacity-25 dark:ring-opacity-25",
            },
        },
        button: {
            color: {
                white: {
                    solid: "bg-white dark:bg-gray-200",
                },
                bgreen: {
                    solid: "bg-bgreen-600 text-white dark:bg-bgreen-500 dark:text-white",
                },
                cream: {
                    solid: "bg-cream-300 dark:bg-neutral-700 dark:text-neutral-300",
                },
                neutral: {
                    solid: "bg-neutral-300 dark:bg-neutral-300",
                },
                nav: {
                    solid: "bg-bgreen-100 text-bgreen-600/90 p-1",
                },
            },
            icon: {
                loading: "",
            },
            default: {
                size: "sm",
                variant: "solid",
                color: "primary",
                loadingIcon: "i-svg-spinners-3-dots-move",
            },
            rounded: "rounded-md",
        },
        card: {
            background: "bg-white dark:bg-neutral-900/60",
            base: "border-0",
            ring: "ring-1 ring-gray-200 dark:ring-neutral-900/80",
            shadow: "shadow-md",
            rounded: "rounded-md",
            header: {
                padding: "p-4",
            },
            footer: {
                padding: "p-4",
            },
            body: {
                padding: "p-4",
            },
        },
        input: {
            size: {
                md: "text-base",
            },
            rounded: "rounded-md",
            default: {
                loadingIcon: "i-svg-spinners-3-dots-fade",
            },
            icon: {
                loading: "",
            },
        },

        formGroup: {
            label: {
                base: "block text-sm font-medium",
            },
            error: "mt-1 text-red-500 dark:text-red-400",
        },

        select: {
            size: {
                md: "text-base",
            },
            rounded: "rounded-md",
        },

        pagination: {
            base: "dark:bg-neutral-600",
        },

        modal: {
            wrapper: "fixed inset-0 flex z-50",
            overlay: {
                base: "fixed inset-0 transition-opacity",
                background: "bg-gray-800/50 dark:bg-gray-800/75",
                transition: {
                    enter: "ease-in-out duration-500",
                    enterFrom: "opacity-0",
                    enterTo: "opacity-100",
                    leave: "ease-in-out duration-500",
                    leaveFrom: "opacity-100",
                    leaveTo: "opacity-0",
                },
            },
            shadow: "shadow-none",
            background: "bg-none",
        },
        slideover: {
            overlay: {
                background: "bg-gray-800/50 dark:bg-gray-800/75",
            },
        },
        horizontalNavigation: {
            base: "text-xs group relative w-full flex items-center gap-1.5 px-2.5 py-3.5 rounded-md font-medium text-sm focus:outline-none focus-visible:outline-none dark:focus-visible:outline-none focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400 disabled:cursor-not-allowed disabled:opacity-75",
        },
        verticalNavigation: {
            wrapper: "relative",
            base: "text-sm group relative flex items-center gap-1.5 focus:outline-none focus-visible:outline-none dark:focus-visible:outline-none focus-visible:before:ring-inset focus-visible:before:ring-1 focus-visible:before:ring-primary-500 dark:focus-visible:before:ring-primary-400 before:absolute before:inset-px before:rounded-md disabled:cursor-not-allowed disabled:opacity-75",
            ring: "focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
            padding: "px-2.5 py-1.5",
            width: "w-full",
            rounded: "rounded-md",
            font: "font-medium",
            size: "text-sm",
            active: "text-gray-900 dark:text-white before:bg-gray-100 dark:before:bg-gray-800",
            inactive:
                "text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:before:bg-gray-50 dark:hover:before:bg-gray-800/50",
            label: "truncate relative",
            icon: {
                base: "flex-shrink-0 w-5 h-5 relative",
                active: "text-gray-700 dark:text-gray-200",
                inactive:
                    "text-gray-400 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-200",
            },
            avatar: {
                base: "flex-shrink-0",
                size: "2xs",
            },
            badge: {
                base: "flex-shrink-0 ml-auto relative rounded",
                size: "xs",
                color: {
                    white: {
                        solid: "ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-900",
                    },
                    gray: {
                        solid: "ring-1 ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-800",
                    },
                    teal: {
                        solid: "ring-1 ring-inset ring-teal-300 dark:ring-teal-700 text-teal-700 dark:text-teal-200 bg-teal-50 dark:bg-teal-800",
                    },
                    black: {
                        solid: "text-white dark:text-gray-900 bg-gray-900 dark:bg-white",
                    },
                },
            },
            divider: {
                border: {
                    base: "border-neutral-600",
                },
                wrapper: {
                    base: "p-2",
                },
            },
        },
        table: {
            default: {
                sortButton: { icon: "i-heroicons-arrows-up-down-20-solid" },
            },
            thead: "last:border-0 border-0 dark:border-neutral-700",
            base: "w-full table-auto",
            th: {
                base: "last:border-0 first:border-none border border-neutral-200 dark:border-neutral-700 truncate bg-neutral-100 dark:bg-neutral-900/50 text-left",
                padding: "px-2 py-3",
                size: "text-xs",
            },
            td: {
                base: "truncate first:bg-neutral-100 dark:first:bg-neutral-900/50 bg-white dark:border-neutral-700 dark:bg-neutral-800",
                padding: "py-1 px-2",
            },
        },
    },
});
